<template>
  <div id="main">
    <el-main>
      <bread-crumb :type="4" :status="1"></bread-crumb>
      <div class="section">
        <img
          src="@/assets/fonts/SUCCESS.png"
          style="width:100px;height:100px;"
        />
        <div class="pay-status">取消报名成功</div>
        <div class="testBox">
          <div>{{ studentInfo.student_name }}同学，你已成功取消“{{ examInfo.exam_name }}”，考试信息如下：</div>
          <div v-if="examInfo.exam_type == 7">预定考点省份：{{ examInfo.province }}</div>
          <div v-else>预定考点：{{ examInfo.city }}</div>
          <div>考试时间：{{ examInfo.execute_time }}</div>
          <div v-if="examInfo.exam_type != 7" style="color:#999999;font-size:14px;margin-top:30px">
            注：报名费用将于3-5个工作日原路返回至您的账户，请关注账户信息，如果有疑问，请联系考务：service@thussat.com。
          </div>
        </div>
        <div class="btnBox">
          <el-button
          @click="toViewInfo()"
            style="background:#fff;color:#563279;border:1px solid #563279"
            >查看报名信息</el-button
          >
          <el-button @click="toExamList()" style="background:#563279;color:#fff;border:1px solid #563279"
            >返回考试列表</el-button
          >
          <el-button style="background:#563279;color:#fff;border:1px solid #563279" @click="toSignUp()"
                     :disabled="isDisable">
            <span v-if="examInfo.exam_type == 7">进入考试</span>
            <span v-else>重新报名</span>
          </el-button>
        </div>
      </div>
    </el-main>
  </div>
</template>

<script>
import breadCrumb from "c/breadcrumb";
import { getOrderInfo } from "r/index/order.js";
// import { signUpInit } from "r/index/signUp";
import {signUpInitReq} from "common/request";

export default {
  data() {
    return {
      examInfo:{},
      studentInfo:{},
      isDisable: false, // 禁用 false-不禁用，true-禁用
    };
  },
  created(){
    if(!window.localStorage.getItem('exam_uuid') || window.localStorage.getItem('exam_uuid')=='' || window.localStorage.getItem('exam_uuid')=='null'){
       return this.$router.replace({name:'examlist'})
     }
    this.getOrderInfo()
  },
  methods: {
    // 获取订单信息
    async getOrderInfo() {
      let { data: res } = await getOrderInfo({ exam_uuid: window.localStorage.getItem('exam_uuid'), order_no:window.localStorage.getItem('order_no') });
      console.log(res);
      if (res.code !== 200) return this.$message.error(res.message);
      this.examInfo = res.info;
      this.studentInfo = res.student;
      if(this.examInfo.status != 0){
        return this.$router.replace({name:'examlist'})
      }
    },
    // 查看报名信息
    toViewInfo(){
      window.localStorage.setItem("exam_uuid", this.examInfo.exam_uuid)
      window.localStorage.setItem("order_no", this.examInfo.order_no)
      this.$router.replace({name: "ViewInfo"})
    },
    // 返回考试列表
    toExamList(){
      this.$router.replace({name: "examlist"})
    },
    // 重新报名
    async toSignUp(){
      if (this.isDisable) {
        return false;
      }
      this.isDisable = true;
      let examuuid = window.localStorage.getItem("exam_uuid")
      await signUpInitReq(examuuid);
      this.isDisable = false;

      // const { data: res } = await signUpInit({ exam_uuid: window.localStorage.getItem('exam_uuid') });
      // console.log(res, "报名初始化完成");
      // if (res.code !== 200) {
      //   if(res.code == 4002139){
      //     await this.$router.replace({name:'StepOne', query:{applicationstatus:0}})
      //     return this.$message.info(res.message);
      //   }else if(res.code == 4002131){
      //     await this.$router.replace({name:'OrderList'})
      //     return this.$message.info(res.message);
      //   }
      //   return this.$message.error(res.message);
      // }
      // window.localStorage.setItem("exam_uuid", res.exam_uuid);
      // window.localStorage.setItem("order_no", res.order_no);
      // this.isDisable = false;
      // if (res.status == 1) {
      //   console.log("报名-填写邀请码");
      //   await this.$router.push({name: "invatation",});
      // } else if (res.status == 2) {
      //   // 跳过邀请码
      //   console.log("报名-选择考点城市");
      //   await this.$router.push({name: "SignUpLists"});
      // } else if (res.status == 3) {
      //   // 绑定邀请码
      //   console.log("报名-选择考点城市");
      //   await this.$router.push({name: "SignUpLists"});
      // } else if (res.status == 4) {
      //   console.log("报名-确认报名信息");
      //   await this.$router.push({name: "ConfirmInfo"});
      // } else if (res.status == 5) {
      //   console.log("报名-待支付状态");
      //   await this.$router.push({name: "ConfirmInfo"});
      // } else if (res.status == 7) {
      //   console.log("报名-订单超时");
      //   await this.$router.push({name: "SignUpLists"});
      // } else if (res.status == 8) {
      //   console.log("报名-取消报名");
      //   await this.$router.push({name: "invatation"});
      // }
    }
  },
  components: {
    breadCrumb,
  },
};
</script>

<style scoped lang="scss">
#main {
  width: 100%;
  min-height: 80%;
  overflow: auto;
  .el-main {
    max-width: 1300px;
    margin: 6px auto;
    padding: 20px 50px;
  }
  .section {
    background: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 60px 0 80px;
    .pay-status {
      height: 16px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 16px;
      margin: 17px 0 30px;
    }
    .testBox {
      width: 620px;
      height: 108px;
      margin-bottom: 130px;
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      div:last-child{
        line-height: 20px;
      }
    }
    .btnBox {
      .el-button {
        width: 140px;
        margin-right: 40px;
        border: 1px solid #563279;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>
